<template>
  <div
    ref="DotMenu"
    class="DotMenu"
    :class="rootClasses"
    @click.stop.prevent>
    <!-- Dots -->
    <Dots
      v-if="type === 'dots'"
      :onClick="onMenuOpen" />

    <!-- Arrow -->
    <div
      v-if="type === 'arrow'"
      class="ArrowWrap"
      @click="onMenuOpen">
      <div class="Inner">
        <AngleRight />
      </div>
    </div>
  </div>
</template>

<script>
import AngleRight from '@/assets/svg/angle-right.svg?inline'
import Dots from '@/components/DotMenu/Dots.vue'
import EventBus from '@/EventBus'
import { scrollTo } from '@/globals/javascript/_util/util'

export default {
  name: 'DotMenu',
  props: {
    useComponent: {
      type: [Object, Boolean],
      required: true,
    },
    dataToComponent: {
      type: Object,
      required: false,
      default: () => {},
    },
    position: {
      type: String,
      required: false,
      default: 'absolute', // 'absolute' | 'normal' | 'small-button-list'
    },
    type: {
      type: String,
      required: false,
      default: 'dots', // 'dots' | 'arrow' | 'hidden'
    },
    size: {
      type: String,
      required: false,
      default: 'normal', // 'normal' | 'small'
    },
    menuOpenTrigger: {
      type: Number,
      required: false,
      default: 0,
    },
    title: {
      type: String,
      required: false,
      default: '',
    },
    scrollOnOpen: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      menuID: '',
      isShowing: false,
    }
  },
  computed: {
    rootClasses() {
      return {
        Hide: !this.useComponent,
        PositionNormal: this.position === 'normal',
        PositionSmallButtonList: this.position === 'small-button-list',
        PositionAbsolute: this.position === 'absolute',
        HiddenButton: this.type === 'hidden',
        IsShowing: this.isShowing,
      }
    },
    MenuWrapClasses() {
      return {
        SizeNormal: this.size === 'normal',
        SizeSmall: this.size === 'small',
      }
    },
  },
  watch: {
    menuOpenTrigger(to) {
      if (to) {
        this.onMenuOpen()
      }
    },
  },
  methods: {
    onBackDropClick() {
      this.isShowing = !this.isShowing
    },
    onMenuOpen() {
      this.menuID = new Date().getTime()
      this.$store.dispatch('setGlobalMenuComponent', this.useComponent)
      this.$store.dispatch('setGlobalMenuProps', {
        menuID: this.menuID,
        size: this.size,
        title: this.title,
        menuElement: this.$refs.DotMenu,
      })
      this.$store.dispatch('setGlobalMenuDataToComponent', this.dataToComponent)
      this.$store.dispatch('openGlobalMenu')

      requestAnimationFrame(() => {
        this.scrollIntoView()
      })
    },
    onCloseMenu() {
      this.isShowing = false
    },
    onPassToParent({ menuID, event }) {
      if (menuID !== this.menuID) {
        return
      }

      if (typeof event === 'string') {
        this.$emit(event)
        return
      }

      this.$emit(event.name, event.value)
    },
    scrollIntoView() {
      if (!this.scrollOnOpen) {
        return
      }

      scrollTo({
        element: this.$el,
        offset: -100,
        checkBetween: true,
      })
    },
  },
  components: {
    AngleRight,
    Dots,
  },
  created() {
    EventBus.$on('global-menu-pass-to-parent', this.onPassToParent)
  },
  destroyed() {
    EventBus.$off('global-menu-pass-to-parent', this.onPassToParent)
  },
}
</script>

<style lang="stylus" scoped>
  .DotMenu
    position relative
    cursor pointer
    &.Hide
      opacity 0
      cursor default
      pointer-events none
    &.PositionNormal
      box(40px, 50px)
    &.PositionSmallButtonList
      box(30px, 30px)
      margin-left 5px
    &.PositionAbsolute
      box(50px)
      position absolute
      top 0
      right 0
      z-index $dot_menu
    &.HiddenButton
      box(100%)
      position absolute
      top 0
      right 0
      pointer-events none
      &.IsShowing
        pointer-events all

  .ArrowWrap
    box(100%)
    flex-center-children()
    .Inner
      box(26px)
      flex-center-children()
      border-radius 5px
      background-color rgba($color_grey_lighter, 0.75)
    svg
      box(14px)
      transform rotate(90deg)
      fill $color_grey_dark
      margin-bottom -1px
</style>
